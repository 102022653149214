import { useEffect } from 'react';

import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';

import { isDevelopment, SENTRY_DSN } from '~/utils/config.client';

export function initSentryBrowser() {
  if (!SENTRY_DSN || isDevelopment) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
  });
}

export function captureRemixErrorBoundaryError({ error }: { error: unknown }) {
  Sentry.captureRemixErrorBoundaryError(error);
}
